import React, { useState, useRef, createRef } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import TextField from "./Inputs/TextField";
import TextArea from "./Inputs/TextArea";
import Recaptcha from "./Inputs/Recaptcha";
import { postFormData } from "./api/Api";
import { ScrollToTop, SendMail, FormValidation, gtmEvent } from "../components/common/utils";
import _ from "lodash";
import "./Form.scss";
import {Link} from "gatsby";
const ContactModalForm = (props) => {
    const recaptchaRef = useRef();
    const myRef = createRef(null);
    const initFormState = {
        formname: "Contact Form",
        fname: '',
        telephone: '',
        email: '',
        lname: '',
        message: '',
        agent_name: (props.agent?.Name)? props.agent?.Name : '',
        agent_email: (props.agent?.Email)? props.agent?.Email : ''
    }
    const validationFields = ['fname', 'email', 'telephone', 'lname'];
    const [formState, setFormState] = useState(initFormState);
    const [errors, setErrors] = useState({});
    const [errorFlag, setErrorFlag] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [btnProcess, setBtnProcess] = useState(false);

    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        var validation = FormValidation(formState, validationFields);
        if (validation.status) {
            setErrorFlag(false)
            setErrors(validation.errors)
            const token = await recaptchaRef.current.executeAsync();
            if (token) {
                setBtnProcess(true)
                var postData = _.cloneDeep(formState)
                postData['extra'] = formState;
                recaptchaRef.current.reset();
                postFormData(postData).then(async apiRes => {
                    setThankyou(true)
                    setFormState(initFormState)
                    setBtnProcess(false);
                    // scrollTop();
                    myRef?.current?.scrollIntoView({ behavior: 'smooth'})
                    setTimeout(() => {
                        setThankyou(false)
                    }, 9000)
                    // GTM tracking event 
                    gtmEvent({ formType: 'Contact - Person', formName: `Contact - ${props.agent?.Name}` })
                    // lets send mail
                    // postData['from_bcc'] = "marketing@strakers.co.uk";
                    // postData['to_bcc'] = "marketing@strakers.co.uk";
                    postData['email_temp_user'] = 'team_user';
                    postData['email_temp_admin'] = 'team_admin';
                    postData['email_subject_user'] = 'Strakers - Team Contact';
                    postData['email_subject_admin'] = 'Strakers - Team Contact';
                   await SendMail(postData);
                });
            }
        } else {
            setErrors(validation.errors)
            setErrorFlag(true)
            // scrollTop();
            myRef?.current?.scrollIntoView({ behavior: 'smooth'})
        }
    }

    const scrollTop = () => {
        ScrollToTop(document.getElementById('form-section'), 100)
    }
    const errClass = (field) => {
        if (errors[field]) {
            return 'field-error';
        }
        return ''
    }
    return (
        <React.Fragment>
   <section className='form-section scroll-margin-top' id="form-section" ref={myRef} >
    <div className="form form--contact bg-white">
    <div className="form__body">
          <div className="form-row">

                <Row>
                    <Col md={12}>

                                {showthankyou && <div className="alert alert-success">
                                    Thank you for your interest. A member of our team will contact you shortly.
                                </div>}
                                {
                                    errorFlag &&
                                    <div className="alert alert-danger alert-error">Highlighted fields are required | invalid</div>
                                }

                                <div className='form-inputs'>
                                    <Row>
                                    <Col md={6}>
                                    <TextField
                                        label="First Name"
                                        name={`fname`}
                                        placeholder=""
                                        onChange={handleChange}
                                        value={formState[`fname`]}
                                        className={errClass(`fname`)}
                                    />
                                    </Col>
                                    <Col md={6}>
                                    <TextField
                                        label="Last Name"
                                        name={`lname`}
                                        placeholder=""
                                        onChange={handleChange}
                                        value={formState[`lname`]}
                                        className={errClass(`lname`)}
                                    />
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md={6}>
                                    <TextField
                                        label="Email Address"
                                        name={`email`}
                                        placeholder=""
                                        onChange={handleChange}
                                        className={errClass(`email`)}
                                        value={formState[`email`]}
                                    />
                                    </Col>
                                    <Col md={6}>
                                    <TextField
                                        type="number"
                                        label="Telephone"
                                        name={`telephone`}
                                        placeholder=""
                                        onChange={handleChange}
                                        className={errClass(`telephone`)}
                                        value={formState[`telephone`]}
                                    />
                                    </Col>
                                    </Row>
                                </div>
                                <TextArea
                                    label="Message"
                                    rows={5}
                                    name="message"
                                    placeholder=""
                                    onChange={handleChange}
                                    value={formState[`message`]}
                                />
                                <Recaptcha recaptchaRef={recaptchaRef} />
                                <div className="form__footer">
                                <button type="submit"
                                    disabled={btnProcess}
                                    onClick={(event) => { handleSubmit(event) }}
                                    className='btn btn--yellow fz14 fw-bolder'
                                >Email to {props.agent?.Name}</button>
                                <div className='form-footer-text'>
                                            By clicking Submit, you agree to our <Link to="/terms-and-conditions/">Terms & Conditions</Link> and <Link to="/privacy-policy/">Privacy Policy</Link>.
                                        </div>
                                </div>
                           
                    </Col>
                </Row>
                </div>
                        </div>
                        </div>

            </section>
        </React.Fragment >
    )

}
export default ContactModalForm;