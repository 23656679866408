/**
 * External dependencies
 */
 import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"

/**
 * Internal dependencies
 */
import sales from "./static/sales"
import CardSale from "./card-sale"
import useNewsSales from "./use-news-sales"
import Button from "../button/button"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import "./styles/_index.scss"
import { useStaticQuery, graphql } from "gatsby";
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import {
  TEAM_PAGE_URL
} from "../../components/common/site/constants"
import {
  isIOS,
  isMobile
} from "react-device-detect";
import Carousel from "../carousel/carousel"
const OtherNewsSales = (props) => {
//   const RELATED_TEAM = gql`
//   query GetTeam($URL: String!) {
//     offices(publicationState: LIVE
//       where: {URL: $URL, Publish: true}) {
//         Choose_Team_Members{
//         id
//             imagetransforms
//             Name
//             Email
//             Designation
//             Video_Url
//             URL
//             Image {
//               alternativeText
//               url
//             }
//           }
//     }
//   }
// `
// const {
//   loading: relatedteamsloading,
//   error: relatedteamserror,
//   data: relatedteams,
// } = useQuery(RELATED_TEAM, {
//   variables: { URL: props.Offices },
// })
const propertiesCollection = useMemo(
  () =>
  props?.teamData?.map((x, index) => {
    let processedImages = JSON.stringify({})
    if (x.imagetransforms?.Image_Transforms) {
      processedImages = x.imagetransforms.Image_Transforms
    }
      return (
        <Col
        key={index}
        className={classNames("news-sales__col")}
      >
        <CardSale
        imagetransforms={processedImages}
        id={x.id}
          image={x.Image}
          video={x.Video_Url}
          name={x.Name}
          position={x.Designation}
          href={TEAM_PAGE_URL.alias+'/'+x.URL+'/'}
          index={index}
          agent={x}
          imgname={"team.Image.list"}
        />
      </Col>
      )
    }),
  [props?.teamData]
)
    
  return (
    props?.teamData?.length > 0 ?
    <div className="home-news grey">
    <Container className="news-sales others">
    <Container>
    <Row className="g-0 align-items-md-center align-items-lg-start">
    <Col>
      <h4>Meet more of our team</h4>
      </Col>
    </Row>
    </Container>
      <Row className="news-sales__row flex-column flex-md-row">
      {props?.teamData?.length > 0 &&
                <Carousel
                className="news-land__row other flex-column flex-md-row"
                data={propertiesCollection}
                controllers={{ buttons: isMobile && props?.teamData?.length > 1 ? true : !isMobile && props?.teamData?.length > 4 ? true : false }}
                isFluidContainer={false}
              />
        }
      </Row>
    </Container>
    </div>
 : null )
}

export default OtherNewsSales
