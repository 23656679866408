/**
 * External dependencies
 */
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import Button from "../button/button"
import { graphql } from "gatsby"
/**
 * Internal dependencies
 */
 import ContentBlock from "../content/Content";
import "./styles/_index.scss"

const TeamBanner = ({Banner_block}) => (
  <div className="home-banner">
    <Container className="container-big">
      <Row className="home-banner__inner position-relative d-flex align-items-center justify-content-lg-center g-0">
        <Col className="home-banner__image position-absolute w-100 h-100">
          <img
            src={Banner_block?.Image?.url}
            alt={Banner_block?.Image?.alternativeText}
            style={{ height: "100%", width: "100%" }}
          />
        </Col>
        <Container className="home-banner__content m-0">
          <Row className="home-banner__entry position-relative d-flex align-items-center g-0">
            <Col>
            <ContentBlock Content={Banner_block?.Title} />

              <div className="home-banner__description">
              <ContentBlock Content={Banner_block?.Content} />
              </div>

              <Button href={Banner_block?.Banner_Cta?.Url?.Alias} color="yellow">
                <Button.Text>{Banner_block?.Banner_Cta?.Label}</Button.Text>
              </Button>
            </Col>
            <Col xs="auto">
              <h2 className="h1-like d-none d-lg-block mb-lg-0">
                {Banner_block?.Big_Title}
              </h2>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  </div>
)

export default TeamBanner

