/**
 * External dependencies
 */
import React, {useState} from "react"
import { Row, Col, Container } from "react-bootstrap"
import ContactModalForm from "../../Forms/ContactModalForm"
import Modal from 'react-bootstrap/Modal';
/**
 * Internal dependencies
 */
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import useTeamDetailInfo from "./use-team-detail-info"
import Button from "../button/button"
import "./styles/_index.scss"
import ContentBlock from "../content/Content";
const TeamDetailInfo = (props) => {
  let processedImages = JSON.stringify({})
  if (props?.data?.imagetransforms?.Image_Transforms) {
    processedImages = props?.data?.imagetransforms?.Image_Transforms
  }
  const [showForm, setShowForm] = useState(false);
  const openform = () => {
          setShowForm(!showForm)
  }
  return (
    <Container className="team-detail-info pt-80 pb-80">
      <Row className="g-0 justify-content-lg-between">
        <Col xs="12" lg="auto" className="team-detail-info__video">
          <VideoPlaceholder
            image={props?.data?.Image}
            video={props?.data?.Video_Url}
            imagetransforms={processedImages}
            id={props?.data?.id}
            alt={props?.data?.Image?.alternativeText}
            imgname={"team.Image.detail"}
            playBtnOnRight={props?.data?.Video_Url ? true : false}
          />
        </Col>

        <Col
          className="team-detail-info__content order-lg-first"
          xs="12"
          lg="auto"
        >
          <h1>{props?.data?.Name}</h1>
          <p className="body-m">{props?.data?.Designation}</p>
          <Row className="team-detail-info__cta mb-5 mt-5 mt-md-6 flex-column flex-md-row">
            <Col className="pb-2 mb-4 pe-md-4">
           
              <Button displayAsLink={false}  onClick={openform} color="yellow">
                <Button.Icon>
                  <i className="icon icon--mail" />
                </Button.Icon>
                <Button.Text>Email {props?.data?.Name}</Button.Text>
              </Button>
              <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm} onHide={() => { setShowForm(false) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <div className="form__head">
          <h4>Contact to {props?.data?.Name}</h4>
        </div>
</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <ContactModalForm agent={props?.data}/>  
                                            </Modal.Body>

                                        </Modal>

            </Col>
            <Col className="ps-md-4">
              <Button tel={props?.data?.Phone}>
                <Button.Icon>
                  <i className="icon icon--phone" />
                </Button.Icon>
                <Button.Text>{props?.data?.Phone}</Button.Text>
              </Button>
            </Col>
          </Row>
          <ContentBlock Content={props?.data?.Content} />
        </Col>
      </Row>
    </Container>
  )
}

export default TeamDetailInfo
