/**
 * External dependencies
 */
import { Link } from "gatsby"
import React, { useState } from "react"
import { Row, Col } from "react-bootstrap"

/**
 * Internal dependencies
 */
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import ContactModalForm from "../../Forms/ContactModalForm"
import classNames from "classnames"
import Modal from 'react-bootstrap/Modal';
const CardSale = ({ imgname, imagetransforms, id, image, video, name, position, href, index, agent }) => {
  const [showForm, setShowForm] = useState(false);
  const [indexI, setIndex] = useState(null);
  const openform = (i) => {
    if (indexI === i) {
      setIndex(null)
      setShowForm(false)
    }
    else {
      setIndex(i)
      setShowForm(true)
    }
  }
  return (

    <Card className="card-sale">
      {video ? (
        <Card.Head onImageHoverZoom image={image} imgname={imgname} imagetransforms={imagetransforms} id={id} video={video} 
        alt={name?name+ " - Strakers Estate Agents":"Teams - Strakers Estate Agents"}
        href={href}
         playBtnOnRight={true} />
      ) : (
        <Link 
        onClick={()=> {sessionStorage.setItem('teamid', name?.replace(/ /g, '_'))}}
        to={href}>
          <Card.Head onImageHoverZoom
            image={image} imgname={imgname} imagetransforms={imagetransforms} id={id}
            video={video}
            alt={name?name+ " - Strakers Estate Agents":"Teams - Strakers Estate Agents"}
            playBtnOnRight={true}
          />
        </Link>
      )}

      <Card.Body>
        <h5>
          <Link
          onClick={()=> {sessionStorage.setItem('teamid', name?.replace(/ /g, '_'))}}
          to={href}>{name}</Link>
        </h5>
        <p>{position}</p>
      </Card.Body>

      <Card.Footer>
        <Row className="g-0 flex-nowrap justify-content-between">
          <Col>
            <Link
              class="link-icon blue-link-hover d-flex align-items-center"
              onClick={()=> {sessionStorage.setItem('teamid', name?.replace(/ /g, '_'))}}
              to={href}
            >
              <div
                className={`link-icon__text fw-bolder fz14 position-relative order-0`}
              >
                Bio
              </div>
              <i className={classNames(`icon icon--text`)}></i>
            </Link>
            {/* <LinkIcon customLink={true} href={`/${TEAM_PAGE_URL?.alias}/${href}`} text="Bio" icon="text" /> */}
          </Col>

          <Col>
            <Card.Delimiter />
          </Col>

          <Col>
            <a href='javascript:void(0)' onClick={() => openform(index)} className="link-icon blue-link-hover d-flex align-items-center"

            >
              <div
                className={`link-icon__text fw-bolder fz14 position-relative order-0`}
              >
                Email
              </div>
              <i className={classNames(`icon icon--mail`)}></i>
            </a>
            {/* <LinkIcon onClick={() => openform(index)} text="Email" icon="mail" /> */}
          </Col>
          <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm && index === indexI} onHide={() => { setShowForm(false); setIndex(null) }}>
            <Modal.Header closeButton>
              <Modal.Title> <div className="form__head">
                <h4>Contact to {agent?.Name}</h4>
              </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ContactModalForm agent={agent} />
            </Modal.Body>

          </Modal>
        </Row>
      </Card.Footer>
    </Card>
  )
}

export default CardSale
